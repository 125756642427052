var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace, Getter } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import NumberRange from "~/components/common/number-range.vue";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import { Watch } from "vue-property-decorator";
import { CollectionData } from "~/config/types/collection-data";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
var collectionManageModule = namespace("collection-manage");
var inrushCaseModule = namespace("inrush-case");
//@Auth(109)
var PendingCollectionCase = /** @class */ (function (_super) {
    __extends(PendingCollectionCase, _super);
    function PendingCollectionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.deptIds = [];
        _this.loading = {
            state: false,
        };
        _this.switchCaseType = "1";
        _this.money = "";
        _this.queryParamsModel = {
            principalId: "",
            // 批次号
            batchNumber: "",
            personalName: "",
            cardNo: "",
            phone: "",
            deptCode: "",
            account: "",
            collectionRecordCount: 0,
            city: "",
            // 调解员
            currentCollector: "",
            handsNumber: "",
            collectionStatus: "",
            caseStatus: "",
            comment: "",
            delegationDate: [],
            endCaseDate: [],
            currentCollectorName: "",
            address: "",
            employerName: "",
            delegationMonth: "",
            cardNoType: "",
            unhealthyFlag: "",
            receiveName: "",
            batchNo: "",
            extensionInfo: "",
            extensionAgent: "",
            groupType: "",
            leftAmt: {
                min: "",
                max: "",
            },
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
            userName: "",
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.dialog = {
            detail: false,
        };
        _this.currentCase = {};
        _this.collectionStatus = [];
        _this.collectorDate = [];
        return _this;
    }
    PendingCollectionCase.prototype.onRouteChange = function (val) {
        if (!val)
            return;
        this.queryParamsModel.principalId = val.params.principalId;
        this.refreshData();
        this.getConfigByPrin();
    };
    PendingCollectionCase.prototype.cellStyle = function (_a) {
        var _this = this;
        var row = _a.row;
        if (this.collectionStatus && row.collectStatus) {
            var statusArr = row.collectStatus.split(",");
            var findArr_1 = [];
            statusArr.forEach(function (x) {
                var a = _this.collectionStatus.find(function (status) { return x === status.name; });
                if (a) {
                    findArr_1.push(a);
                }
            });
            if (findArr_1.length > 0) {
                row.color = findArr_1.reduce(function (a, b) {
                    return b.sort > a.sort ? b : a;
                }).color;
            }
        }
        if (!row.color)
            return;
        return { color: row.color + " !important" };
    };
    PendingCollectionCase.prototype.getConfigByPrin = function () {
        var _this = this;
        this.loading.state = true;
        this.custConfigService.getConfigByPrin(this.queryParamsModel.principalId, this.loading).subscribe(function (data) {
            _this.collectionStatus = data.collStates;
        });
    };
    PendingCollectionCase.prototype.mounted = function () {
        this.collectorDate = this.collectorList.userNameList.map(function (x) {
            return { value: x.name, userName: x.userName };
        });
    };
    PendingCollectionCase.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    PendingCollectionCase.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    PendingCollectionCase.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.collectionCaseService.queryCollectionByPrincipal(this.queryParamsModel, this.pageService, this.sortService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
        this.amount();
    };
    PendingCollectionCase.prototype.amount = function () {
        var _this = this;
        this.collectionCaseService.queryCollectionAmtByPrincipal(this.queryParamsModel).subscribe(function (data) {
            _this.money = data;
        });
    };
    /**
     * 手动分案
     */
    PendingCollectionCase.prototype.caseAllotClick = function () {
        if (this.selectionList.length === 0) {
            this.$message("请选择分案的案件!");
            return;
        }
        //选案获取案件id
        var caseIds = this.selectionList.map(function (v) { return v.caseId; });
        //提交id
        this.updateSelectedCase(caseIds);
        this.dialog.caseAllot = true;
    };
    PendingCollectionCase.prototype.onSwitchCase = function (type) {
        switch (type) {
            case "1":
                this.queryParamsModel.groupType = "GROUP_CASE";
                this.refreshData();
                break;
            case "2":
                this.queryParamsModel.groupType = "PERSONAL_CASE";
                this.refreshData();
                break;
            default:
                this.queryParamsModel.groupType = "GUIDE_CASE";
                this.refreshData();
                break;
        }
    };
    /**
     * 机构改变
     */
    PendingCollectionCase.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.queryParamsModel.deptCode = valueArray[valueArray.length - 1];
    };
    /**
     * 策略分案
     */
    PendingCollectionCase.prototype.strategicClick = function () { };
    PendingCollectionCase.prototype.onBatchNumberClick = function (currentCollection) {
        var data = new CollectionData(this.queryParamsModel.principalId, currentCollection.id, this.queryParamsModel);
        this.openExecutionPage(data);
    };
    __decorate([
        Dependencies(PageService)
    ], PendingCollectionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], PendingCollectionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], PendingCollectionCase.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], PendingCollectionCase.prototype, "custConfigService", void 0);
    __decorate([
        collectionManageModule.Action
    ], PendingCollectionCase.prototype, "openExecutionPage", void 0);
    __decorate([
        State
    ], PendingCollectionCase.prototype, "userData", void 0);
    __decorate([
        State
    ], PendingCollectionCase.prototype, "principalList", void 0);
    __decorate([
        State
    ], PendingCollectionCase.prototype, "collectorList", void 0);
    __decorate([
        inrushCaseModule.Mutation
    ], PendingCollectionCase.prototype, "updateSelectedCase", void 0);
    __decorate([
        Getter
    ], PendingCollectionCase.prototype, "departmentData", void 0);
    __decorate([
        Watch("$route", { immediate: true })
    ], PendingCollectionCase.prototype, "onRouteChange", null);
    PendingCollectionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                FollowAllQueryBatch: FollowAllQueryBatch,
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                FollowAllQuery: FollowAllQuery,
            },
        })
    ], PendingCollectionCase);
    return PendingCollectionCase;
}(Vue));
export default PendingCollectionCase;
